export const MAX_CYCLES_TO_COMPARE = 3;

export const CYCLE_DEFAULT_HEX = '#47BEA0';
export const CYCLE_COMPARE_1_HEX = '#80D5FF';
export const CYCLE_COMPARE_2_HEX = '#FFB200';

export interface BrandDashboardData {
  age_18_24: number;
  age_25_34: number;
  age_35_44: number;
  age_45_54: number;
  age_55_64: number;
  age_65_74: number;
  age_75_plus: number;
  gender_male: number;
  gender_female: number;
  gender_nonBinary: number;
  gender_preferNotSay: number;
  count_reviewed: number;
  count_ordered: number;
  review_5_star: number;
  review_4_star: number;
  review_3_star: number;
  review_2_star: number;
  review_1_star: number;
  packaging_5_star: number;
  packaging_4_star: number;
  packaging_3_star: number;
  packaging_2_star: number;
  packaging_1_star: number;
  flavor_5_star: number;
  flavor_4_star: number;
  flavor_3_star: number;
  flavor_2_star: number;
  flavor_1_star: number;
  texture_5_star: number;
  texture_4_star: number;
  texture_3_star: number;
  texture_2_star: number;
  texture_1_star: number;
  price_5_star: number;
  price_4_star: number;
  price_3_star: number;
  price_2_star: number;
  price_1_star: number;
  purchase_intent_5_star: number;
  purchase_intent_4_star: number;
  purchase_intent_3_star: number;
  purchase_intent_2_star: number;
  purchase_intent_1_star: number;
  unique_5_star: number;
  unique_4_star: number;
  unique_3_star: number;
  unique_2_star: number;
  unique_1_star: number;
  avg_packagingRate: number;
  avg_flavorRate: number;
  avg_textureRate: number;
  avg_priceRate: number;
  avg_uniqueProductConsideration: number;
  diet_none: number;
  diet_keto: number;
  diet_lowCarb: number;
  diet_carnivore: number;
  diet_paleo: number;
  diet_vegetarian: number;
  diet_flexitarian: number;
  diet_pescatarian: number;
  diet_vegan: number;
  diet_other: number;
  store_supermarketChain: number;
  store_supermarketHealthFood: number;
  store_localGroceryStore: number;
  store_farmerMarket: number;
  store_warehouseClub: number;
  store_onlineGrocery: number;
  store_convenienceStore: number;
  store_specialityFoodStore: number;
  store_other: number;
  recommended_yes: number;
  recommended_no: number;
  discomfort_glucose: number;
  discomfort_gi: number;
  discomfort_brainFog: number;
  discomfort_other: number;
  discomfort_none: number;
  reviews: {
    onboarding_gender: string;
    onboarding_age: string;
    rating: number;
    review: string;
    product_id: string;
    product_title: string;
    product_image: string;
  }[];
  cycle_numbers: number[];
}

export const DEFAULT_DASHBOARD_DATA: BrandDashboardData = {
  age_18_24: 0,
  age_25_34: 0,
  age_35_44: 0,
  age_45_54: 0,
  age_55_64: 0,
  age_65_74: 0,
  age_75_plus: 0,
  gender_male: 0,
  gender_female: 0,
  gender_nonBinary: 0,
  gender_preferNotSay: 0,
  count_ordered: 0,
  count_reviewed: 0,
  review_5_star: 0,
  review_4_star: 0,
  review_3_star: 0,
  review_2_star: 0,
  review_1_star: 0,
  packaging_5_star: 0,
  packaging_4_star: 0,
  packaging_3_star: 0,
  packaging_2_star: 0,
  packaging_1_star: 0,
  flavor_5_star: 0,
  flavor_4_star: 0,
  flavor_3_star: 0,
  flavor_2_star: 0,
  flavor_1_star: 0,
  texture_5_star: 0,
  texture_4_star: 0,
  texture_3_star: 0,
  texture_2_star: 0,
  texture_1_star: 0,
  price_5_star: 0,
  price_4_star: 0,
  price_3_star: 0,
  price_2_star: 0,
  price_1_star: 0,
  purchase_intent_5_star: 0,
  purchase_intent_4_star: 0,
  purchase_intent_3_star: 0,
  purchase_intent_2_star: 0,
  purchase_intent_1_star: 0,
  unique_5_star: 0,
  unique_4_star: 0,
  unique_3_star: 0,
  unique_2_star: 0,
  unique_1_star: 0,
  avg_packagingRate: 0,
  avg_flavorRate: 0,
  avg_textureRate: 0,
  avg_priceRate: 0,
  avg_uniqueProductConsideration: 0,
  diet_none: 0,
  diet_keto: 0,
  diet_lowCarb: 0,
  diet_carnivore: 0,
  diet_paleo: 0,
  diet_vegetarian: 0,
  diet_flexitarian: 0,
  diet_pescatarian: 0,
  diet_vegan: 0,
  diet_other: 0,
  store_supermarketChain: 0,
  store_supermarketHealthFood: 0,
  store_localGroceryStore: 0,
  store_farmerMarket: 0,
  store_warehouseClub: 0,
  store_onlineGrocery: 0,
  store_convenienceStore: 0,
  store_specialityFoodStore: 0,
  store_other: 0,
  recommended_yes: 0,
  recommended_no: 0,
  discomfort_glucose: 0,
  discomfort_gi: 0,
  discomfort_brainFog: 0,
  discomfort_other: 0,
  discomfort_none: 0,
  reviews: [],
  cycle_numbers: [],
};
