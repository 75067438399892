export const EVENTS = {
  PRODUCT_LISTING_VIEWED: 'product listing page: viewed',
  HOW_IT_WORKS_VIEWED: 'how it works page: viewed',
  HOW_IT_WORKS_CLICKED: 'how it works: clicked',
  PROMOTIONS_CLICKED: 'promotions: clicked',
  ONBOARDING_STEP_VIEWED: 'onboarding step: viewed',
  SIGNUP_VIEWED: 'signup page: viewed',
  HOME_PAGE_VIEWED: 'home page: viewed',
  HOME_CLICKED: 'home: clicked',
  PRODUCT_PAGE_VIEWED: 'product page: viewed',
  DIET_TAG_CLICKED: 'diet tag: clicked',
  PRODUCT_BLOCK_CLICKED: 'product block: clicked',
  ADD_CART_CLICKED: 'add cart: clicked',
  ONBOARDING_FLOW_STARTED: 'onboarding flow: started',
  CHECK_EMAIL_PAGE_VIEWED: 'onboarding check email page: viewed',
  CONFIRM_ORDER_SUBMITTED: 'confirm order: submitted',
  REMOVE_PRODUCT_CLICKED: 'remove product: clicked',
  CHECKOUT_CLICKED: 'checkout: clicked',
  CART_CLOSED: 'cart: closed',
  NAVIGATIONAL_CART_CLICKED: 'navigational cart: clicked',
  CANT_LOGIN_EMAIL_SUBMITTED: 'cant login: email submitted',
  CANT_LOGIN_BUTTON_CLICKED: 'cant login button: clicked',
  LOGIN_VIEWED: 'login: viewed',
  LOGIN_SUBMITTED: 'login: submitted',
  SIGNUP_SUBMITTED: 'signup page: submitted',
  ONBOARDING_STEP_CLICKED: 'onboarding step: clicked',
  SHOP_ALL_CLICKED: 'shop all: clicked',
  NUTRITION_AND_INGREDIENTS_CLICKED: 'nutrition and ingredients: clicked',
  PRODUCT_RATINGS_CLICKED: 'ratings collapse: clicked',
  CONTACT_US_CLICKED: 'contact us: clicked',
  SAVE_ADDRESS_CLICKED: 'save address: clicked',
  EXPLORE_ALL_PRODUCTS_CLICKED: 'explore all products: clicked',
  LEARN_HOW_TOKENS_WORK_CLICKED: 'learn how tokens work: clicked',
  HOW_CYCLES_WORK_CLICKED: 'how cycles work: clicked',
  REDEEM_TOKEN_CLICKED: 'redeem token: clicked',
  REDEEM_CODE_CLICKED: 'redeem code: clicked',
  CODE_COPY_CLICKED: 'code copied: clicked',
  VISIT_SITE_CLICKED: 'visit site: clicked',
  FAQ_CLICKED: 'faq: clicked',
  PROMOTIONS_PAGE_VIEWED: 'promotions page: viewed',
  SAMPLE_REVIEW_STEP_VIEWED: 'sample review step: viewed',
  SAMPLE_REVIEW_SUBMITTED: 'sample review: submitted',
  SAMPLE_REVIEW_STEP_CLICKED: 'sample review step: clicked',
  SAMPLE_REVIEW_STARTED: 'sample review: started',
  GET_PREMIUM_TODAY_CLICKED: 'get premium today: clicked',
  RENEW_PREMIUM_NOTIFICATION_CLICKED: 'renew: clicked',
  UNLOCK_WITH_PREMIUM_CYCLE_CLICKED: 'unlock with premium: clicked',
  LEAVE_A_REVIEW_CLICKED: 'leave a review: clicked',
  COMPLETE_A_REVIEW_CLICKED: 'complete a review: clicked',
  LOGIN_ONE_TIME_LINK_CLICKED: 'login with one-time link: clicked',
  LOGIN_PASSWORD_INSTEAD_CLICKED: 'login with password instead: clicked',
  LOGIN_CARB_MANAGER_SSO_CLICKED: 'login with Carb Manager SSO: clicked',
  PRODUCT_ORDERED: 'product: ordered',
  NOT_RECEIVED_DAMAGE_CLICKED: 'not received damaged: clicked',
  CANT_REVIEW_SURVEY_SUBMITTED: 'cant review survey: submitted',
  BRAND_CHANGE_SUBSCRIPTION_CLICKED: 'brand change subscription: clicked',
  BRAND_SELECT_PLAN_CLICKED: 'brand select plan: clicked',
  BRAND_CANCEL_SUBSCRIPTION_CLICKED: 'brand cancel subscription: clicked',
  BRAND_CHANGE_PAYMENT_CLICKED: 'brand manage payment: clicked',
  BRAND_SIDEBAR_NAV_LINK_CLICKED: 'brand sidebar navigation: clicked',
  BRAND_SUBSCRIPTION_TAB_VIEWED: 'subscription tab: viewed',
  BRAND_USERS_TAB_VIEWED: 'user management tab: viewed',
  BRAND_PROMOTIONS_PAGE_VIEWED: 'brand promotions page: viewed',
  BRAND_PROMOTIONS_ADD_CLICKED: 'brand add new promotions: clicked ',
  BRAND_PRODUCTS_PAGE_VIEWED: 'brand products page: viewed',
  BRAND_PRODUCTS_ADD_CLICKED: 'brand add product: clicked',
  BRAND_PRODUCTS_PAGE_SORT_APPLIED: 'brand products page sort: applied',
  BRAND_CONTACT_SUPPORT_CLICKED: 'brand contact support: clicked',
  BRAND_PRODUCT_EDIT_MODAL_VIEWED: 'brand product edit modal: viewed',
  BRAND_PRODUCT_EDIT_MODAL_UPDATED: 'brand product edit modal: updated',
  BRAND_PRODUCT_SEARCH_INITIATED: 'brand product search: initiated',
  BRAND_PRODUCT_TABLE_ACTION_CLICKED: 'brand product table action: clicked',
  BRAND_DASHBOARD_VIEWED: 'brand dashboard: viewed',
  BRAND_FILTER_BUTTON_CLICKED: 'brand filter button: clicked',
  BRAND_CYCLE_FILTER_CLICKED: 'brand cycle filter: clicked',
  BRAND_USER_TYPE_FILTER_CLICKED: 'brand user type filter: clicked',
  BRAND_DASHBOARD_CHART_CLICKED: 'brand dashboard chart: clicked',
  BRAND_ORDERS_EXPORT_DATA_CLICKED: 'brand orders export data: clicked',
  BRAND_ORDERS_MARK_FULFILLED_APPLIED:
    'brand orders mark all as fulfilled: applied',
  BRAND_ORDERS_FILTER_APPLIED: 'brand orders filter: applied',
  BRAND_ORDERS_PAGE_SORT_APPLIED: 'brand orders page sort: applied',
  BRAND_PROFILE_PAGE_VIEWED: 'brand profile page: viewed',
  BRAND_PROFILE_EDIT_CLICKED: 'edit brand profile: clicked',
  BRAND_PROFILE_EDIT_SAVED: 'edit brand profile: saved',
  BRAND_PROFILE_PREVIEW_CLICKED: 'preview brand profile: clicked',
  BRAND_ONBOARDING_SIGNUP_PAGE_VIEWED: 'brand onboarding signup page: viewed',
  BRAND_ONBOARDING_FLOW_STARTED: 'brand onboarding flow: started',
  BRAND_ONBOARDING_EXPIRED_LINK: 'brand onboarding expired link: viewed',
  BRAND_ONBOARDING_EMAIL_VERIFICATION_CONTINUE:
    'brand onboarding email verification: continue',
  BRAND_ONBOARDING_EMAIL_VERIFICATION_RESEND:
    'brand onboarding email verification: resend',
  BRAND_ONBOARDING_STEP_CLICKED: 'brand onboarding step: clicked',
  BRAND_ONBOARDING_COMPLETED_MODAL_SHOWN:
    'brand completed onboarding modal: shown',
  BRAND_ONBOARDING_COMPLETED_MODAL_DISMISSED:
    'brand completed onboarding modal: dismissed',
  BRAND_ONBOARDING_COMPLETED_MODAL_IMAGE_SCROLLED:
    'brand completed onboarding modal image: scrolled',
};

export const PAGES = {
  PRODUCT_LISTING: 'product_listing_page',
  HOME: 'home_page',
  PRODUCT: 'product_page',
  WALLET: 'wallet_page',
  CHECKOUT_STARTED: 'checkout_started_page',
  HOW_IT_WORKS: 'how_it_works_page',
  ORDER_CONFIRMATION: 'order_confirmation_page',
  ONBOARDING_START: 'onboarding_start_page',
  ONBOARDING_SIGNUP: 'onboarding_signup_page',
  ONBOARDING_CHECK_EMAIL: 'onboarding_check_email_page',
  ONBOARDING_STEP: 'onboarding_step_page',
  LOGIN: 'login_page',
  LOGIN_ONE_TIME_LINK: 'login_one_time_link',
  LOGIN_HELP: 'login_help_page',
  PROMOTIONS: 'promotions_page',
  BRANDS_DASHBOARD_DEMOGRAPHICS: 'brands_dashboard_demographics',
  BRANDS_DASHBOARD_RATINGS: 'brands_dashboard_ratings',
  BRANDS_DASHBOARD_REVIEWS: 'brands_dashboard_reviews',
  BRANDS_DASHBOARD_INSIGHTS: 'brands_dashboard_insights',
  BRANDS_PRODUCTS: 'brands_products',
  BRANDS_ACCOUNT_HUB: 'brands_account_hub',
  BRANDS_ACCOUNT_DETAILS: 'brands_account_details',
  BRANDS_ACCOUNT_ORDERS: 'brands_account_orders',
  BRANDS_ONBOARDING_SIGNUP: 'brands_onboarding_signup',
  BRANDS_ONBOARDING_EMAIL_VERIFICATION: 'brands_onboarding_email_verification',
  BRANDS_ONBOARDING_STEP: 'brands_onboarding_step',
  BRANDS_SETTINGS_SUBSCRIPTION: 'brands_settings_subscription',
  BRANDS_SETTINGS_USERS: 'brands_settings_users',
  BRANDS_PROMOTIONS_ACTIVE: 'brands_promotions_active',
  BRANDS_PROMOTIONS_ARCHIVED: 'brands_promotions_archived',
};

export const SECTIONS = {
  PRODUCT_BLOCK: 'product_block',
  STICKY_CART: 'sticky_cart',
  NAVIGATIONAL_CART: 'navigational_cart',
  CONTINUE_SHOPPING: 'continue_shopping',
  CLOSE_CART_CLICKED: 'x_clicked',
  PAGE_CART: 'page_cart',
  CART: 'cart',
  WALLET_CTA: 'wallet_cta',
  WALLET_CYCLE: 'wallet_cycle',
  WALLET_NOTIFICATION: 'my_wallet_notification',
  PROMOTIONAL_BANNER: 'promotional_banner',
  PROMOTIONAL_MODAL: 'promotional_modal',
  BRAND_SITE_LABEL: 'brand_site_label',
  PROMOTIONS_CTA: 'promotions_cta',
};

export default EVENTS;
