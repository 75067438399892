const useNow = () => {
  const now = ref(new Date().getTime());

  const update = useThrottleFn(() => {
    now.value = new Date().getTime();
  }, 60000);

  useRafFn(update, { immediate: true });

  return now;
};

export default useNow;
