import {
  BrandSubscriptionPlan,
  type Cycle,
  type FastSpringSubscription,
} from '@tn/shared';

export const PLAN_EVENT_ID_MAP: { [key in BrandSubscriptionPlan]: string } = {
  [BrandSubscriptionPlan.Full_1M]: '1_month_subscription',
  [BrandSubscriptionPlan.Full_3M]: '3_month_subscription',
  [BrandSubscriptionPlan.Basic_1M]: '1_month_basic_subscription',
};

export const getCurrentSubscription = async () => {
  const subscription = await $fetch('/api/fastspring/current-subscription');
  return subscription as FastSpringSubscription | null;
};

export const isSubscriptionActiveOrInGracePeriod = ({
  subscription,
  currentCycle,
}: {
  subscription: FastSpringSubscription;
  currentCycle: Cycle;
}) => {
  if (subscription.active === true) {
    return true;
  }

  const endMs = subscription.deactivationDate;
  if (!endMs) {
    return false;
  }

  if (!currentCycle) {
    return false;
  }

  if (endMs > currentCycle.startDate && endMs < currentCycle.endDate) {
    // Grace period if the subscription ended within the current cycle
    return true;
  }

  return false;
};
