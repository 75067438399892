import payload_plugin_n6nFAoY9qs from "/home/runner/work/taste-network/taste-network/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/home/runner/work/taste-network/taste-network/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/taste-network/taste-network/packages/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import anchorscroll_plugin_nifcvYfr4Z from "/home/runner/work/taste-network/taste-network/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import plugin_client_P1Z4TKhjVJ from "/home/runner/work/taste-network/taste-network/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/runner/work/taste-network/taste-network/packages/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_vJieZLDvJT from "/home/runner/work/taste-network/taste-network/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/taste-network/taste-network/packages/web-app/.nuxt/vuefire-plugin.mjs";
import slideovers_oeUu8xcI5w from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/home/runner/work/taste-network/taste-network/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/home/runner/work/taste-network/taste-network/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_fetch_cycles_EYn3RfYuGv from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/1.fetch-cycles.ts";
import _2_rudderstack_client_1tGbzsItHL from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/2.rudderstack.client.ts";
import _3_amplitudeExperiment_client_jq3KgGoOra from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/3.amplitudeExperiment.client.ts";
import _4_amplitudeSessionReplay_client_6XYTFlO6Qh from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/4.amplitudeSessionReplay.client.ts";
import _6_fetch_brand_subscription_I0e2pgePgg from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/6.fetch-brand-subscription.ts";
import fetch_cycles_TjeEqJ76LL from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/fetch-cycles.ts";
import sanitize_fbXCtPd0F2 from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/sanitize.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/taste-network/taste-network/packages/web-app/plugins/sentry.client.ts";
export default [
  payload_plugin_n6nFAoY9qs,
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  anchorscroll_plugin_nifcvYfr4Z,
  plugin_client_P1Z4TKhjVJ,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_vJieZLDvJT,
  vuefire_plugin_8cq4FnzXKb,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  chunk_reload_client_SeG0EjL5Ec,
  _1_fetch_cycles_EYn3RfYuGv,
  _2_rudderstack_client_1tGbzsItHL,
  _3_amplitudeExperiment_client_jq3KgGoOra,
  _4_amplitudeSessionReplay_client_6XYTFlO6Qh,
  _6_fetch_brand_subscription_I0e2pgePgg,
  fetch_cycles_TjeEqJ76LL,
  sanitize_fbXCtPd0F2,
  sentry_client_shVUlIjFLk
]