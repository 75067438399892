import { defineStore } from 'pinia';
import BrandPaywallModal from '@/components/brands/PaywallModal.vue';
import {
  BrandBasicPlans,
  BrandFullPlans,
  type BrandSubscriptionPlan,
  type FastSpringSubscription,
} from '@tn/shared';

export const useBrandSubscriptionStore = defineStore(
  'brandSubscription',
  () => {
    const modal = useModal();
    const currentSubscription = ref<FastSpringSubscription | null>(null);
    const accessUntilDate = ref<number | null>(null);
    const activeOrGracePeriodSubscription = ref(false);
    const isFetchingCurrentSubscription = ref(false);

    const cycleStore = useCyclesStore();
    const currentCycle = cycleStore.currentCycle;

    // Getters
    const subscriptionPlan = computed(
      () => currentSubscription.value?.product as BrandSubscriptionPlan | null
    );

    const hasBasicPlan = computed(() => {
      const plan = currentSubscription.value
        ?.product as BrandSubscriptionPlan | null;
      return Boolean(plan && BrandBasicPlans.includes(plan));
    });

    const hasFullPlan = computed(() => {
      const plan = currentSubscription.value
        ?.product as BrandSubscriptionPlan | null;
      return Boolean(plan && BrandFullPlans.includes(plan));
    });

    const hasActiveSubscription = computed(
      () => !!currentSubscription.value?.active
    );

    const hasActiveOrGracePeriodSubscription = computed(
      () => !!activeOrGracePeriodSubscription.value
    );

    const hasActiveOrGracePeriodFullSubscription = computed(() =>
      Boolean(hasActiveOrGracePeriodSubscription.value && hasFullPlan.value)
    );

    // Actions
    async function fetchCurrentSubscription() {
      const { headers } = authenticatedRequestHeaders();

      isFetchingCurrentSubscription.value = true;
      const subscription = await $fetch<FastSpringSubscription | null>(
        '/api/fastspring/current-subscription',
        { headers }
      );
      isFetchingCurrentSubscription.value = false;

      if (!currentCycle || !subscription) {
        activeOrGracePeriodSubscription.value = false;
        accessUntilDate.value = null;
        return;
      }

      currentSubscription.value = {
        ...subscription,
      };

      const isActive = isSubscriptionActiveOrInGracePeriod({
        subscription: currentSubscription.value,
        currentCycle,
      });
      activeOrGracePeriodSubscription.value = isActive;
      accessUntilDate.value = isActive ? currentCycle.endDate : null;
    }

    const openPaywallModal = () => {
      modal.open(BrandPaywallModal);
    };

    return {
      // State
      currentSubscription,
      accessUntilDate,
      isFetchingCurrentSubscription,

      // Getters
      subscriptionPlan,
      hasBasicPlan,
      hasFullPlan,
      hasActiveSubscription,
      hasActiveOrGracePeriodSubscription,
      hasActiveOrGracePeriodFullSubscription,

      // Actions
      fetchCurrentSubscription,
      openPaywallModal,
    };
  }
);
