import * as sessionReplay from '@amplitude/session-replay-browser';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const deviceId = useState<string>('deviceId');

  try {
    const { isBrand } = await useCurrentUserDetails();
    const currentRoute = useRoute();
    const isBrandRoute = currentRoute.path.includes('/brands');
    let sampleRate = config.public.amplitudeReplaySessionSampleRate;

    if (sampleRate && (isBrand || isBrandRoute)) {
      // We want to record all sessions from brands
      sampleRate = 1;
    }

    await sessionReplay.init(config.public.amplitudeApiKey, {
      deviceId: deviceId.value,
      sampleRate: sampleRate,
    }).promise;

    const setSessionReplaySessionId = (sessionId: number) => {
      sessionReplay.setSessionId(sessionId);
    };

    const getSessionReplaySessionId = () => {
      return sessionReplay.getSessionId();
    };

    const getSessionReplayProperties = () => {
      return sessionReplay.getSessionReplayProperties();
    };

    return {
      provide: {
        setSessionReplaySessionId,
        getSessionReplaySessionId,
        getSessionReplayProperties,
      },
    };
  } catch (error) {
    console.error('Error initializing session replay:', error);

    return {};
  }
});
